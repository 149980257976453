import {
  get,
  post
} from "@get/http";

export const collectInfo = (op_type = 0, target = 0, info = {}) => {
   return post('/v3.MemberCardPoster/collectInfo', {
    data: {
      op_type, //操作类型[0:用户信息 1:点击 2:下载]
      target, //目标ID
      info //更多的采集信息
    }
  })

}