<template>
  <div class="list">
    <van-empty
      :description="errorText"
      v-if="errorText && errorText.length != 0"
      image="error"
    >
      <van-button round class="bottom-button " @click="$router.go(-1)"
        >返回</van-button
      >
      <van-button
        round
        type="primary"
        class="bottom-button"
        @click="$router.push({ name: 'HomeIndex' })"
        >回到首页</van-button
      >
    </van-empty>

    <template v-else>
      <div class="generate_tab">
        <ul class="gt-tab">
          <li
            v-for="item in tabOption"
            :key="item.class_id"
            @click="clickSetTab(item.class_id)"
          >
            <span :class="item.class_id == tabCur ? 'act' : ''">{{
              item.title
            }}</span>
            <div
              :style="
                `background : ${item.class_id == tabCur ? '#ff4848' : ''}`
              "
            ></div>
          </li>
        </ul>
        <van-dropdown-menu active-color="#222">
          <van-dropdown-item
            v-model="timeValue"
            :options="timeOption1"
            @change="changeTimeValue"
          />
        </van-dropdown-menu>
      </div>
      <!--  -->
      <div class="generate_image">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <ul class="gi-image">
            <li v-for="item in imgOptions" :key="item.id">
              <img :src="item.content" />
              <div
                class="check"
                :class="findIsCheckImage(item) ? 'checked' : ''"
                @click="checkThisImage(item)"
              ></div>
            </li>
          </ul>
        </van-list>
      </div>

      <div class="generate-btn">
        <div class="btn btn1" @click="okimage(0)">
          带个人信息生成({{ chooeseImg.length }}/9)
        </div>
        <div class="btn btn2" @click="okimage(1)">
          直接生成({{ chooeseImg.length }}/9)
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { DropdownMenu, DropdownItem, Toast, List, Empty, Button } from "vant";
import { get, post } from "@get/http";
import { collectInfo } from "./js/index.js";
export default {
  components: {
    vanDropdownMenu: DropdownMenu,
    vanDropdownItem: DropdownItem,
    vanList: List,
    vanEmpty: Empty,
    vanButton: Button,
  },
  created() {
    this.getList();
  },
  methods: {
    okimage(type) {
      if (this.chooeseImg.length == 0) {
        Toast("至少选择1张哦~");
        return false;
      }
      sessionStorage["19jiang_create_mg"] = JSON.stringify(this.chooeseImg);
      this.$router.push({
        name: "generate",
        query: {
          type,
        },
      });

      collectInfo(2, 0, this.chooeseImg);
    },
    clickSetTab(item) {
      this.tabCur = item;
      Toast.loading({
        message: "加载分类中..",
        forbidClick: true,
        duration: 0,
      });
      this.loading = true;
      this.page = 1;
      this.finished = false;

      this.getData();
      collectInfo(1, item);
      Toast.clear();
    },
    findIsCheckImage(id) {
      return this.chooeseImg.find((item) => item.id == id.id);
    },
    checkThisImage(orgin) {
      let data = this.chooeseImg.find((item, index) => {
        if (item.id == orgin.id) {
          this.chooeseImg.splice(index, 1);
          return item;
        }
      });
      if (!data) {
        if (this.chooeseImg.length == 9) {
          Toast("一次最多只能选择9张哦~");
          return false;
        }
        this.chooeseImg.push(orgin);
      }
    },
    async getData() {
      let data = await post(
        "/v3.MemberCardPoster/getList",
        {
          params: {
            class_id: this.tabCur,
            type: 0,
            time: this.timeValue,
            pageSize: this.pageSize,
            page: this.page,
          },
        },
        { result: true, toast: false, resultKey: "all" }
      );
      if (data.code == 10000) {
        if (this.page == 1) {
          this.imgOptions = [];
        }

        if (data.result.list.length == 0) {
          this.finished = true;
          return false;
        }

        this.imgOptions.push(...data.result.list);
        this.page++;
        this.loading = false;
        if (data.result.list.length < this.pageSize) {
          this.finished = true;
        }
      } else {
        this.errorText = data.message;
      }
    },
    changeTimeValue(time) {
      Toast.loading({
        message: "加载分类中..",
      });
      this.loading = true;
      this.page = 1;
      // this.finished = false;
      // this.getData();
    },
    async getList() {
      let data = await post(
        "/v3.MemberCardPoster/getClass",
        {},
        { result: true, toast: false, resultKey: "all" }
      );

      if (data.code == 10000) {
        this.tabOption = data.result.list.sort((a, b) => {
          if (a.sort > b.sort) {
            return 1;
          }
          if (a.sort < b.sort) {
            return -1;
          }
          return 0;
        });

        if (this.tabOption && this.tabOption.length != 0)
          this.tabCur = this.tabOption[0].class_id;
        this.getData();
      } else {
        this.errorText = data.message;
      }
    },
    onLoad() {
      this.getData();
    },
  },

  data() {
    return {
      tabCur: 0,
      pageSize: 20,
      page: 1,
      loading: false,
      finished: false,
      tabOption: [],
      errorText: "",
      chooeseImageNumber: 1,
      timeValue: 0,
      timeOption1: [
        { text: "所有时间", value: 0 },
        {
          text: "一天内",
          value: Math.round(new Date().getTime() / 1000 - 1 * 24 * 3600),
        },
        {
          text: "一周内",
          value: Math.round(new Date().getTime() / 1000 - 7 * 24 * 3600),
        },
        {
          text: "一月内",
          value: Math.round(new Date().getTime() / 1000 - 30 * 24 * 3600),
        },
        {
          text: "一年内",
          value: Math.round(new Date().getTime() / 1000 - 365 * 24 * 3600),
        },
      ],
      chooeseImg: [],
      imgOptions: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.generate_tab {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #fff;
  .gt-tab {
    display: flex;
    height: rem(105);
    align-items: center;
    width: rem(750);
    padding: 0 rem(30);
    overflow-x: auto;
    // flex-wrap: wrap;
    white-space: nowrap;
    li {
      position: relative;
      padding: 0 rem(20);
      display: inline-block;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: rem(100);
      }
      span {
        color: #999999;
        font-size: rem(32);
      }

      .act {
        color: #222222;
        font-weight: bold;
        font-size: rem(32);
      }

      div {
        width: rem(36);
        height: rem(6);
        border-radius: rem(3);
        margin: rem(2) auto 0;
      }
    }
  }
}

.generate_image {
  box-sizing: border-box;
  padding: rem(16) rem(30) rem(130);
  .gi-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      position: relative;
      width: rem(337);
      height: rem(337);
      margin-bottom: rem(16);

      img {
        width: 100%;
        height: 100%;
        border-radius: rem(16);
        display: block;
      }
      .check {
        position: absolute;
        border: rem(3) solid #ffffff;
        width: rem(44);
        height: rem(44);
        border-radius: 50%;
        bottom: rem(16);
        right: rem(16);
      }
      .checked {
        background: url("~@image/h5/create_checkd.png") no-repeat #ff4848 center;
        background-size: rem(30) rem(25);
      }
    }
  }
}

.generate-btn {
  width: 100%;
  height: rem(112);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .btn {
    width: rem(344);
    height: rem(84);
    color: #ffffff;
    font-weight: bold;
    background: #ff4848;
    font-size: rem(30);
    box-shadow: 0px 0px rem(8) rgba(0, 0, 0, 0.1);
    text-align: center;
    line-height: rem(84);
  }
  .btn1 {
    border-radius: rem(48) 0 0 rem(48);
  }
  .btn2 {
    border-radius: 0 rem(48) rem(48) 0;
    background: #fff;
    color: #ff4848;
  }
}

::v-deep {
  .van-dropdown-menu__bar {
    box-shadow: none;
  }
  .van-dropdown-menu__item {
    justify-content: left;
  }
}
.list {
  width: rem(750);
  overflow: hidden;
}

.bottom-button {
  display: block;
  margin: 0 auto;
  margin-bottom: rem(30);
  width: rem(300);
}
</style>
